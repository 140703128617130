import { connect } from "react-redux";

const data = store => {
  return {
    signedIn: store.app.signedIn
  };
};

const actions = dispatch => {
  return {};
};

export default component =>
  connect(
    data,
    actions
  )(component);
