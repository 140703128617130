import React, { Component } from "react";
import classNames from "classnames";
import {
  CircularProgress,
  FormControl,
  List,
  Select,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Button,
  IconButton
} from "@material-ui/core";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faLockAlt,
  faPlus,
  faTrash
} from "@fortawesome/pro-light-svg-icons";
import style from "./../style";
import settings from "./../settings";

class DoubleSelectPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      pending: false,
      value: this.props.value,
      primarySelect: this.props.primarySelect,
      secondarySelect: this.props.secondarySelect
    };
    this.handlePrimaryChange = this.handlePrimaryChange.bind(this);
    this.handleSecondaryChange = this.handleSecondaryChange.bind(this);
    this.save = this.save.bind(this);
    this.handleRemoveListItem = this.handleRemoveListItem.bind(this);
    this.handleAddListItem = this.handleAddListItem.bind(this);
  }
  componentWillUnmount() {
    if (this.timer) window.clearTimeout(this.timer);
  }
  handlePrimaryChange(event) {
    let newState = { ...this.state };
    newState.primarySelect.value = event.target.value;
    this.setState(newState);
  }
  handleSecondaryChange(event) {
    let newState = { ...this.state };
    newState.secondarySelect.value = event.target.value;
    this.setState(newState);
  }
  handleRemoveListItem(index) {
    let newState = { ...this.state };
    let value = newState.value.filter((v, i) => i !== index);
    this.save(value);
  }
  handleAddListItem() {
    const newState = { ...this.state };
    const value = newState.value;
    let tmpValue = {};
    tmpValue[newState.primarySelect.name] = newState.primarySelect.value;
    tmpValue[newState.secondarySelect.name] = newState.secondarySelect.value;
    value.push(tmpValue);
    this.save(value);
  }
  save(value) {
    this.setState({
      ...this.state,
      success: false,
      pending: true
    });

    if (this.timer) window.clearTimeout(this.timer);

    this.timer = window.setTimeout(async () => {
      const success = await Promise.resolve(this.props.onSave(value || null));
      let newState = { ...this.state };
      newState.success = success;
      newState.pending = false;
      newState.value = value;
      newState.secondarySelect.value = "";
      newState.primarySelect.value = "";
      this.setState(newState);
      if (this.state.success) {
        window.setTimeout(() => {
          this.setState({
            ...this.state,
            success: false
          });
        }, settings.successTime);
      }
    }, settings.delay);
  }
  render() {
    const { classes, xs, sm, md, lg, readOnly } = this.props;
    const { primarySelect, secondarySelect } = this.state;
    const hasError =
      this.props.error && !this.props.readOnly && !this.state.pending;
    const isSuccess =
      this.state.success && !this.props.readOnly && !this.state.pending;
    const hasValues =
      (readOnly && this.props.value.length) ||
      (!readOnly && this.state.value.length)
        ? true
        : false;

    return (
      <Grid item {...{ xs, sm, md, lg }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="instant-form-control">
              <FormControl className={classes.formControl}>
                <label className={classes.labelNotFloating}>
                  <Typography
                    color="textSecondary"
                    className={classNames([
                      isSuccess ? classes.success : "",
                      hasError ? classes.danger : ""
                    ])}
                    variant="caption"
                    gutterBottom
                    style={{
                      display: "inline"
                    }}
                  >
                    {this.props.label}
                  </Typography>
                  {this.state.pending && (
                    <CircularProgress
                      className={classes.default}
                      style={{ marginLeft: "5px", display: "inline-block" }}
                      size={13}
                    />
                  )}
                  {readOnly && (
                    <FontAwesomeIcon
                      className={classes.default}
                      style={{ marginLeft: "5px" }}
                      icon={faLockAlt}
                    />
                  )}
                  {hasError && (
                    <FontAwesomeIcon
                      className={classes.danger}
                      style={{ marginLeft: "5px" }}
                      icon={faExclamationCircle}
                    />
                  )}
                  {isSuccess && (
                    <FontAwesomeIcon
                      className={classes.success}
                      style={{ marginLeft: "5px" }}
                      icon={faCheck}
                    />
                  )}
                </label>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Select
              name={primarySelect.name}
              value={primarySelect.value}
              readOnly={readOnly}
              onChange={this.handlePrimaryChange}
              style={{ width: "100%" }}
            >
              {primarySelect.options.map(option => (
                <MenuItem key={"primary-" + option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              name={secondarySelect.name}
              value={secondarySelect.value}
              readOnly={readOnly}
              onChange={this.handleSecondaryChange}
              style={{ width: "100%" }}
            >
              {secondarySelect.options.map(option => (
                <MenuItem key={"primary-" + option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {hasError && (
            <Grid item xs={12}>
              <Typography
                className={classNames([hasError ? classes.danger : ""])}
                variant="body2"
                gutterBottom
                style={{ display: "inline" }}
              >
                {this.props.error}
              </Typography>
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              disabled={readOnly}
              mini={true}
              fullWidth={true}
              style={{ minWidth: 0, minHeight: "100%" }}
              onClick={this.handleAddListItem}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Grid>
          {hasValues && (
            <Grid item xs={12}>
              <List>
                {this.state.value.map((value, index) => {
                  let primaryText = "primary text not found";
                  let secondaryText = "secondary text not found";
                  primarySelect.options.forEach(option => {
                    if (option.value === value[primarySelect.name])
                      primaryText = option.text;
                  });
                  secondarySelect.options.forEach(option => {
                    if (option.value === value[secondarySelect.name])
                      secondaryText = option.text;
                  });
                  return (
                    <ListItem key={"listitem-" + index}>
                      <ListItemText
                        primary={primaryText}
                        secondary={secondaryText}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          disabled={readOnly}
                          onClick={() => this.handleRemoveListItem(index)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(DoubleSelectPicker);
