import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faEngineWarning } from "@fortawesome/pro-light-svg-icons";
import Container from "./../container/Appbar";
import { Offline } from "react-detect-offline";

const style = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    padding: "5px"
  }
});

class Appbar extends Component {
  render() {
    const { classes } = this.props;
    return (
      <AppBar>
        <Toolbar>
          <Typography
            variant="subheading"
            color="inherit"
            className={classes.grow}
          >
            Stechuhr
          </Typography>
          <IconButton
            onClick={this.props.signOut}
            color="inherit"
            aria-label="SignOut"
          >
            <FontAwesomeIcon icon={faSignOut} />
          </IconButton>
        </Toolbar>
        <Offline>
          <div className={classes.warning}>
            <Typography
              variant="body1"
              color="inherit"
              className={classes.grow}
            >
              <FontAwesomeIcon
                icon={faEngineWarning}
                style={{ fontSize: "1.4em" }}
              />{" "}
              kein Internet.
            </Typography>
          </div>
        </Offline>
      </AppBar>
    );
  }
}

export default Container(withStyles(style)(Appbar));
