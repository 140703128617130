import React, { Component } from "react";
import Container from "./container/App";
import Login from "./component/Login";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TimeClock from "./component/TimeClock";
import "./styles/main.css";
import theme from "./styles/theme";
import Appbar from "./component/Appbar";

class App extends Component {
  render() {
    const { signedIn } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        {!signedIn && (
          <div className="App">
            <Login />
          </div>
        )}
        {signedIn && (
          <div className="App">
            <Appbar />
            <main>
              <TimeClock />
            </main>
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

export default Container(App);
