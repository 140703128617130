import React, { Component } from "react";
import Container from "./../container/TimerClock";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Online, Offline } from "react-detect-offline";

import {
  faPlay,
  faPause,
  faStop,
  faCheck,
  faTimes
} from "@fortawesome/pro-light-svg-icons";

const settings = {
  delay: 500
};

const styles = theme => ({
  buttonBar: {
    margin: "15px 0px 0px 0px"
  },
  confirmationText: {
    margin: "0px 0px 10px 0px"
  },
  stopButton: {
    color: "#fff",
    backgroundColor: theme.palette.danger.main
  },
  pauseList: {
    padding: "0px",
    listStyle: "none",
    margin: "0px"
  },
  pauses: {
    textAlign: "center",
    padding: "25px 15px 0px 15px"
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  }
});

const addLeadingZero = number => {
  return 10 > number ? "0" + number : "" + number;
};
const convertTime = number => {
  const hours = Math.floor(number / 3600);
  let rest = number % 3600;
  const minutes = Math.floor(rest / 60);
  const seconds = rest - minutes * 60;

  return (
    addLeadingZero(hours) +
    ":" +
    addLeadingZero(minutes) +
    ":" +
    addLeadingZero(seconds)
  );
};

class TimeClock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayTime: "00:00:00",
      timer: false,
      confirmStop: false
    };
  }
  componentWillMount() {
    if (this.state.timer === false && this.props.start) {
      this.startTimer();
    }
  }
  componentWillUnmount() {
    if (this.state.timer !== false) {
      window.clearInterval(this.state.timer);
      this.setState({ ...this.state, timer: false });
    }
  }
  startTimer() {
    this.setState({
      ...this.state,
      timer: window.setInterval(async () => {
        this.setTime();
      }, settings.delay)
    });
  }
  setTime() {
    let time = Date.now() - this.props.start;
    this.props.pauses.forEach((pause, index) => {
      let { end, start } = pause;
      end =
        index === this.props.pauses.length - 1 && typeof end === "undefined"
          ? Date.now()
          : end;
      time = time - (end - start);
    });

    time = Math.floor(time / 1000);
    this.setState({
      ...this.state,
      displayTime: convertTime(time)
    });
  }
  stopTimer() {
    if (this.state.timer !== false) {
      window.clearInterval(this.state.timer);
    }
    this.setState({
      ...this.state,
      confirmStop: false,
      timer: false,
      displayTime: "00:00:00",
      latestPause: "00:00:00"
    });
    const { pauses } = this.props;
    const end = Date.now();
    const start = this.props.start;
    console.log(start, end, pauses);
    this.props.stopTimer(start, end, pauses);
  }
  toggleConfirmStop() {
    this.setState({ ...this.state, confirmStop: !this.state.confirmStop });
  }
  render() {
    const { classes, pauses, pause, start } = this.props;

    return (
      <div className="TimeClock" style={{ height: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <Typography
            component="h2"
            className={
              start && !pause
                ? classes.success
                : start && pause
                  ? classes.warning
                  : ""
            }
            variant="display3"
          >
            {this.state.displayTime}
          </Typography>
          {this.props.start === false && (
            <div className={classes.buttonBar}>
              <Button
                variant="contained"
                onClick={e => {
                  e.preventDefault();
                  this.props.startTimer();
                  this.startTimer();
                }}
              >
                Starten
                <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faPlay} />
              </Button>
            </div>
          )}
          {this.props.start !== false &&
            this.state.confirmStop === false &&
            this.props.pause === false && (
              <div className={classes.buttonBar}>
                <Button
                  variant="contained"
                  onClick={e => {
                    e.preventDefault();
                    this.props.startPause();
                  }}
                >
                  Pause
                  <FontAwesomeIcon
                    style={{ marginLeft: "10px" }}
                    icon={faPause}
                  />
                </Button>
                <Button
                  variant="contained"
                  className={classes.stopButton}
                  style={{ marginLeft: "10px" }}
                  onClick={e => {
                    e.preventDefault();
                    this.toggleConfirmStop();
                  }}
                >
                  Stop
                  <FontAwesomeIcon
                    style={{ marginLeft: "10px" }}
                    icon={faStop}
                  />
                </Button>
              </div>
            )}
          {this.props.start !== false &&
            this.state.confirmStop === true &&
            this.props.pause === false && (
              <div className={classes.buttonBar}>
                <Online>
                  <Typography
                    component="p"
                    variant="body1"
                    className={classes.confirmationText}
                  >
                    Wollen Sie die Zeitmessung wirklich beenden?
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={e => {
                      e.preventDefault();
                      this.stopTimer();
                    }}
                  >
                    Ja
                    <FontAwesomeIcon
                      style={{ marginLeft: "10px" }}
                      icon={faCheck}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.stopButton}
                    style={{ marginLeft: "10px" }}
                    onClick={e => {
                      e.preventDefault();
                      this.toggleConfirmStop();
                    }}
                  >
                    Nein
                    <FontAwesomeIcon
                      style={{ marginLeft: "10px" }}
                      icon={faTimes}
                    />
                  </Button>
                </Online>
                <Offline>
                  <Typography
                    component="p"
                    variant="body1"
                    className={classes.confirmationText}
                  >
                    Sie haben derzeit kein Internet und können daher die
                    Zeitmessung nicht beenden.
                  </Typography>
                  <Button
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                    onClick={e => {
                      e.preventDefault();
                      this.toggleConfirmStop();
                    }}
                  >
                    Zurück
                  </Button>
                </Offline>
              </div>
            )}
          {this.props.pause !== false && (
            <div className={classes.buttonBar}>
              <Button
                variant="contained"
                onClick={e => {
                  e.preventDefault();
                  this.props.stopPause();
                }}
              >
                Weiter
                <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faPlay} />
              </Button>
            </div>
          )}
          {this.props.start !== false && (
            <div className={classes.pauses}>
              <Typography component="h4" variant="subheading">
                Pausen
              </Typography>
              {!pauses.length && (
                <Typography component="p" variant="body1">
                  <i>Keine</i>
                </Typography>
              )}
              <ul className={classes.pauseList}>
                {[...pauses].reverse().map((pause, index) => {
                  let { end, start } = pause;
                  end =
                    index === 0 && typeof end === "undefined"
                      ? Date.now()
                      : end;
                  const time = Math.floor((end - start) / 1000);
                  const active = index === 0 && this.props.pause;
                  return (
                    <li key={"pauses-" + index}>
                      <Typography
                        component="p"
                        className={active ? "" : ""}
                        variant="display1"
                      >
                        {convertTime(time)}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Container(withStyles(styles)(TimeClock));
