import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

class Block extends Component {
  render() {
    const { classes, label, children, head } = this.props;

    return (
      <div className={classes.block}>
        <div
          className={classNames([
            head ? classes.head : false,
            head && head.primary ? classes.primary : false
          ])}
        >
          {label && (
            <Typography
              variant="subheading"
              className={classNames([
                head && head.primary ? classes.primary : false
              ])}
            >
              {label}
            </Typography>
          )}
        </div>
        {children}
      </div>
    );
  }
}

const style = theme => ({
  block: {
    padding: "16px",
    backgroundColor: "#fff"
  },
  head: {
    margin: "-16px -16px 16px -16px",
    padding: "16px 16px 10px 16px"
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  }
});

export default withStyles(style)(Block);
