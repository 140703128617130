import { connect } from "react-redux";
import { Customer } from "./../SDKs/DevCloud";
import {
  startTimer,
  stopTimer,
  startPause,
  stopPause
} from "./../action/Timer";

const data = store => {
  return {
    start: store.timer.start,
    pause: store.timer.pause,
    pauses: store.timer.pauses
  };
};

const actions = dispatch => {
  return {
    startTimer: () => {
      dispatch(startTimer());
    },
    stopTimer: (start, end, pauses) => {
      const customer = new Customer();
      customer.task.timeClock.add(start, end, pauses);
      dispatch(stopTimer());
    },
    startPause: () => {
      dispatch(startPause());
    },
    stopPause: () => {
      dispatch(stopPause());
    }
  };
};

export default component =>
  connect(
    data,
    actions
  )(component);
