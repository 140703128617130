import Storage from "./../SDKs/Storage";
const storage = new Storage();

const startTimer = () => {
  const start = Date.now();
  storage.set("timer:start", start);
  return {
    type: "START_TIMER",
    payload: {
      timerStart: start
    }
  };
};

const stopTimer = () => {
  storage.delete("timer:start");
  storage.delete("timer:pause");
  storage.delete("timer:pauses");
  return {
    type: "STOP_TIMER",
    payload: {}
  };
};

const startPause = () => {
  const start = Date.now();
  storage.set("timer:pause", "1");
  return {
    type: "PAUSE_TIMER_START",
    payload: { start }
  };
};

const stopPause = () => {
  const end = Date.now();
  storage.delete("timer:pause");
  return {
    type: "PAUSE_TIMER_STOP",
    payload: { end }
  };
};

export { startTimer, stopTimer, startPause, stopPause };
