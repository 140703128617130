import { connect } from "react-redux";
import { signOut } from "./../action/App";

const data = store => {
  return {};
};

const actions = dispatch => {
  return {
    signOut: () => {
      dispatch(signOut());
    }
  };
};

export default component =>
  connect(
    data,
    actions
  )(component);
