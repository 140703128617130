const store = {
  signedIn: false
};

export default function reducer(state = store, action) {
  let newState = { ...state };
  switch (action.type) {
    case "SIGN_IN":
      newState.signedIn = true;
      return newState;
    case "SIGN_OUT":
      newState.signedIn = false;
      return newState;
    default:
      return newState;
  }
}
