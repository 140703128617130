import React, { Component } from "react";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Grid, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faLockAlt
} from "@fortawesome/pro-light-svg-icons";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import style from "./../style";
import settings from "./../settings";

class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: parseInt(this.props.value, 10)
    };
    this.handelChange = this.handelChange.bind(this);
  }

  componentWillUnmount() {
    if (this.timer) window.clearTimeout(this.timer);
  }

  handelChange(value) {
    this.setState({ value, success: false, pending: true });
    if (this.timer) window.clearTimeout(this.timer);
    this.timer = window.setTimeout(async () => {
      const success = await Promise.resolve(this.props.onSave(value));
      this.setState({ ...this.state, success, pending: false });
      if (this.state.success) {
        window.setTimeout(() => {
          this.setState({ ...this.state, success: false });
        }, settings.successTime);
      }
    }, settings.delay);
  }

  render() {
    const { classes, xs, sm, md, lg, readOnly } = this.props;
    const hasError = this.props.error && !readOnly && !this.state.pending;
    const isSuccess = this.state.success && !readOnly && !this.state.pending;
    return (
      <Grid item {...{ xs, sm, md, lg }}>
        <div className="instant-form-control">
          <label className={classes.labelNotFloating}>
            <Typography
              color="textSecondary"
              className={classNames([
                isSuccess ? classes.success : "",
                hasError ? classes.danger : ""
              ])}
              variant="caption"
              gutterBottom
              style={{
                display: "inline"
              }}
            >
              {this.props.label} -{" "}
              {readOnly ? this.props.value : this.state.value}
            </Typography>
            {this.state.pending && (
              <CircularProgress
                className={classes.default}
                style={{ marginLeft: "5px", display: "inline-block" }}
                size={13}
              />
            )}
            {readOnly && (
              <FontAwesomeIcon
                className={classes.default}
                style={{ marginLeft: "5px" }}
                icon={faLockAlt}
              />
            )}
            {hasError && (
              <FontAwesomeIcon
                className={classes.danger}
                style={{ marginLeft: "5px" }}
                icon={faExclamationCircle}
              />
            )}
            {isSuccess && (
              <FontAwesomeIcon
                className={classes.success}
                style={{ marginLeft: "5px" }}
                icon={faCheck}
              />
            )}
          </label>
          <div
            className={
              readOnly ? classes.rangeInputReadOnly : classes.rangeInput
            }
          >
            {readOnly && (
              <LinearProgress
                variant="determinate"
                value={this.props.value * 10}
              />
            )}
            {!readOnly && (
              <InputRange
                minValue={0}
                maxValue={10}
                value={this.state.value}
                step={1}
                onChange={v => {
                  this.handelChange(v);
                }}
                formatLabel={value => ""}
              />
            )}
          </div>
        </div>
      </Grid>
    );
  }
}

export default withStyles(style)(Range);
