import Storage from "./../SDKs/Storage";
const storage = new Storage();

const signIn = tokens => {
  storage.set("user:accessToken", tokens.accessToken);
  storage.set("user:idToken", tokens.idToken);
  storage.set("user:refreshToken", tokens.refreshToken);
  return {
    type: "SIGN_IN",
    payload: {
      ...tokens
    }
  };
};

const signOut = () => {
  storage.delete("user:accessToken");
  storage.delete("user:idToken");
  storage.delete("user:refreshToken");
  return {
    type: "SIGN_OUT",
    payload: {}
  };
};

export { signIn, signOut };
