import { apiTokenNotSetError, apiNotSetError } from "./helper/Errors";
import Authentication from "./lib/Authentication";
import Customer from "./lib/Customer";
import settings from "./settings.json";

class DevCloudClass {
  init(params) {
    if (!params.apiToken) throw apiTokenNotSetError("Please set an apiToken");
    if (!settings.url)
      throw apiNotSetError("Please set an api in your SDK's config.");
    let config = {
      tokens: {
        app: params.apiToken,
        accessToken: false,
        refreshToken: false,
        idToken: false
      },
      services: params.services || {},
      handler: params.handler || {},
      handlerList: ["tokenChange", "notAuthorized"]
    };

    this.config = config;
  }

  registerHandler(handler) {
    this.config.handler = { ...this.config.handler, ...handler };
  }

  on(event, params = {}) {
    if (this.config.handler[event]) this.config.handler[event](params);
  }

  setTokens(newTokens) {
    this.on("tokenChange", newTokens);
    this.config.tokens = { ...this.config.tokens, ...newTokens };
  }

  getConfig() {
    return this.config;
  }
}

let DevCloud = new DevCloudClass();
export { DevCloud, Authentication, Customer };
