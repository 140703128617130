import { createStore, combineReducers } from "redux";
import app from "./reducer/App";
import timer from "./reducer/Timer";

export default createStore(
  combineReducers({
    app,
    timer
  })
);
