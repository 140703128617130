import { connect } from "react-redux";
import { signIn } from "./../action/App";
import { Authentication, DevCloud } from "./../SDKs/DevCloud";
import Storage from "./../SDKs/Storage";
const storage = new Storage();

const data = store => {
  return {};
};

const actions = dispatch => {
  return {
    autoLogin: () => {
      if (storage.get("user:refreshToken")) {
        const tokens = {
          accessToken: storage.get("user:accessToken"),
          idToken: storage.get("user:idToken"),
          refreshToken: storage.get("user:refreshToken")
        };
        DevCloud.setTokens(tokens);
        dispatch(signIn(tokens));
      }
    },
    login: async (username, password) => {
      const auth = new Authentication();
      const resp = await auth.login(username, password);
      if (resp.accessToken && resp.idToken && resp.refreshToken) {
        dispatch(signIn(resp));
        return true;
      } else {
        return resp;
      }
    },
    challenge: async (challenges, session) => {
      const auth = new Authentication();
      const resp = await auth.challenge(challenges, session);
      dispatch(signIn(resp));
      return resp;
    },
    resetPassword: async email => {
      const auth = new Authentication();
      return await auth.resetPassword(email);
    },
    resetAccount: async email => {
      const auth = new Authentication();
      return await auth.resetAccount(email);
    }
  };
};

export default component =>
  connect(
    data,
    actions
  )(component);
