import React, { Component } from "react";
import { Input, InputLabel, FormControl } from "@material-ui/core";
import { Typography, Grid } from "@material-ui/core";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import style from "./../style";

class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  render() {
    const { classes, xs, sm, md, lg, type, autoComplete } = this.props;
    const hasError = this.props.error && !this.props.readOnly;

    return (
      <Grid item {...{ xs, sm, md, lg }}>
        <div className="instant-form-control">
          <FormControl className={classes.formControl}>
            <InputLabel
              className={classNames([hasError ? classes.labelError : false])}
            >
              {this.props.label}
            </InputLabel>
            <Input
              onChange={e => this.props.onChange(e.target.value)}
              readOnly={this.props.readOnly}
              type={type || "text"}
              value={this.props.value}
              {...{ autoComplete: autoComplete ? "on" : "off" }}
            />
          </FormControl>
          {hasError &&
            typeof this.props.error === "string" && (
              <Typography
                className={classNames([hasError ? classes.danger : ""])}
                variant="body2"
                gutterBottom
                style={{ display: "inline" }}
              >
                {this.props.error}
              </Typography>
            )}
        </div>
      </Grid>
    );
  }
}

export default withStyles(style)(Text);
