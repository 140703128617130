import React, { Component } from "react";
import { FormControl } from "@material-ui/core";
import { FormControlLabel, RadioGroup, Radio, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import style from "./../style";

class RadioStatic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  render() {
    const { classes, xs, sm, md, lg, options } = this.props;

    return (
      <Grid item {...{ xs, sm, md, lg }}>
        <div className="static-form-control">
          <FormControl className={classes.formControl}>
            <RadioGroup
              aria-label="reset type"
              value={this.props.value}
              onChange={e => {
                this.props.onChange(e.target.value);
              }}
            >
              {options.map((option, index) => {
                return (
                  <FormControlLabel
                    key={"radio" + index + option.value}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                    labelPlacement="end"
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>
    );
  }
}

export default withStyles(style)(RadioStatic);
