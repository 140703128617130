import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import { DevCloud } from "./SDKs/DevCloud";
import Storage from "./SDKs/Storage";
const storage = new Storage();
console.log();
if ("rme.datenbanker.at" === window.location.hostname)
  DevCloud.init({
    apiToken:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIzMmQ0N2I1MC03MGIzLTQzZjMtYTU4Ny0yMTdlMzE3NGNlYWUiLCJzY29wZXMiOlsiYWNiZjI0Y2MtOWFjYy00MmViLWFhYzEtZjI4MjRjNTgwMjgxIl19.RQbO6NTT1axM1aK5Tzle_R5NVs_UjidWkaY1-r8Qo0w",
    services: {
      authentication: {
        user: {
          pool: ["309b7e2e-c07d-4804-820a-84343c364128"]
        }
      },
      customer: {
        person: {
          pool: ["c46b0ee4-39a3-4cfe-b502-ffb157e7e5ed"]
        },
        opportunity: {
          pool: ["c46b0ee4-39a3-4cfe-b502-ffb157e7e5ed"]
        },
        task: {
          pool: ["c46b0ee4-39a3-4cfe-b502-ffb157e7e5ed"]
        }
      }
    },
    handler: {
      tokenChange: tokens => {
        storage.set("user:accessToken", tokens.accessToken);
        storage.set("user:idToken", tokens.idToken);
        storage.set("user:refreshToken", tokens.refreshToken);
      }
    }
  });
else
  DevCloud.init({
    apiToken:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIzMTZhMmVhNS1iOTVhLTRlNmItOWY5ZS1iMDZlZjc1MjEyYjIiLCJzY29wZXMiOlsiNzEwNWEwZTItNGEyOC00ZTI4LWIwYmQtODJhNDFjNzgwNmZiIl19.UCc8fNJJt8ikN-20SQWNgm9vJlYFoqVwBKiQxE08evw",
    services: {
      authentication: {
        user: {
          pool: ["afaf03b3-7fed-4680-b00a-fd907c6ed696"]
        }
      },
      customer: {
        person: {
          pool: ["97378976-b084-4c31-9ee2-071f5cad0868"]
        },
        opportunity: {
          pool: ["46321b9c-7abd-4c7d-8e1e-3c2c96e20c96"]
        },
        task: {
          pool: ["66f81f66-2100-4368-93e6-06e339418d3d"]
        }
      }
    },
    handler: {
      tokenChange: tokens => {
        storage.set("user:accessToken", tokens.accessToken);
        storage.set("user:idToken", tokens.idToken);
        storage.set("user:refreshToken", tokens.refreshToken);
      }
    }
  });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
