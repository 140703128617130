import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  shadows: Array(25).fill("none"),
  palette: {
    primary: {
      main: "#5c90d2",
      dark: "#405b87"
    },
    secondary: {
      main: "#337ab7",
      dark: "#254b7a"
    },
    danger: {
      main: "#b7433f",
      dark: "#713632"
    },
    success: {
      main: "#559554",
      dark: "#325d2e"
    },
    warning: {
      main: "#e8db05",
      dark: "#8d8405"
    },
    default: {
      main: "#b9b9b9",
      dark: "#7f7f7f"
    },
    body: {
      main: "#ececec"
    },
    grey: {
      main: "#e0e0e0"
    }
  },
  overrides: {
    Button: {},
    MuiSelect: {
      selectMenu: {
        minHeight: "1.1rem"
      },
      select: {
        "&:focus": {
          background: "none"
        }
      }
    },
    MuiInput: {
      inputType: {
        height: "1.1rem"
      }
    }
  }
});
