import Storage from "./../SDKs/Storage";
const storage = new Storage();

const store = {
  start: Number(storage.get("timer:start")) || false,
  pause: storage.get("timer:pause") === "1",
  pauses: storage.get("timer:pauses")
    ? JSON.parse(storage.get("timer:pauses"))
    : []
};

export default function reducer(state = store, action) {
  let newState = { ...state };
  switch (action.type) {
    case "START_TIMER":
      newState.start = Date.now();
      return newState;
    case "STOP_TIMER":
      newState.start = false;
      newState.pause = false;
      newState.pauses = [];
      return newState;
    case "PAUSE_TIMER_START":
      newState.pause = true;
      newState.pauses.push({ start: action.payload.start });
      storage.set("timer:pauses", JSON.stringify(newState.pauses));
      return newState;
    case "PAUSE_TIMER_STOP":
      newState.pause = false;
      newState.pauses[newState.pauses.length - 1].end = action.payload.end;
      storage.set("timer:pauses", JSON.stringify(newState.pauses));
      return newState;
    default:
      return newState;
  }
}
